@import "src/theme/mixins/utils";

.mainMenu {
  height: 100%;
  display: flex;
  @include pxToRem(gap, 25);

  a.mainMenuLink {
    display: block;
    height: 100%;

    span {
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: solid transparent;
      @include pxToRem(padding-top, 13);
      @include pxToRem(border-bottom-width, 4);
    }

    &.isActive {
      span {
        color: var(--ion-color-primary);
        border-bottom-color: var(--ion-color-primary);
      }
    }

    span:not(.mainMenuLinkIcon):hover {
      color: var(--ion-color-primary);
      border-bottom-color: var(--ion-color-primary);
    }

    .mainMenuLinkIcon {
      @include pxToRem(padding-top, 3);
      @include pxToRem(padding-left, 8);
    }

    .megaMenuWrapper {
      display: none;
      position: absolute;
      background-color: white;
      border: 1px solid var(--wohnsinn-border-color);
      border-radius: var(--wohnsinn-border-radius);
      box-shadow: 0 4px 6px -2px rgb(0 0 0 / 20%);
      @include pxToRem(padding, 24);
      @include pxToRem(padding-top, 16);

      .megaMenu {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 35px 10px;
        @include pxToRem(padding-top, 16);


        .megaMenuLink {
          display: flex;
          @include pxToRem(gap, 10);
          @include pxToRem(max-width, 260);

          &.isActive {
            span {
              color: var(--ion-color-primary);
            }
          }

          .megaMenuLinkText {
            display: flex;
            flex-direction: column;
            @include pxToRem(max-width, 220);
            @include pxToRem(gap, 5);

            span {
              transition: all .2s;
            }
          }

          &:hover {
            .megaMenuLinkText span {
              color: var(--ion-color-primary);
            }
          }
        }
      }

      &:hover {
        display: block;
      }
    }

    &:hover {
      & + .megaMenuWrapper {
        display: block;
      }
    }
  }
}
