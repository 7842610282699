@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  border-top: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(padding-top, 24);
  @include pxToRem(padding-bottom, 24);

  .top,
  .bottom {
    display: flex;
    flex-direction: column;
    @include pxToRem(gap, 24);

    @include for-min-size(md) {
      flex-direction: row;
    }
  }

  .bottom {
    @include pxToRem(margin-top, 24);
  }

  .desk {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 8);
  }
}
