@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(padding-bottom, 16);

  &.formField {
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    background-color: var(--ion-background-color);
    display: flex;
    align-items: center;
    @include pxToRem(padding-right, 16);
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-top, 5);
    @include pxToRem(padding-bottom, 5);
  }

  .toggle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
