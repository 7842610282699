@import "src/theme/mixins/utils";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  background-color: var(--ion-background-color);

  @include pxToRem(padding-bottom, 5);
  @include pxToRem(padding-top, 5);
  @include pxToRem(padding-right, 15);
  @include pxToRem(padding-left, 15);


  .label {
    display: flex;
    align-items: center;
    width: calc(100% - 50px);
    @include pxToRem(gap, 10);

    img {
      @include pxToRem(max-width, 30);
    }
  }

  .nameWrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .iconWrapper {
    display: flex;
    @include pxToRem(gap, 16);

    .icon:hover {
      color: var(--color-primary--strong);

      &.delete {
        color: var(--ion-color-danger);
      }
    }
  }

}
