@import "src/theme/mixins/utils";
@import "src/theme/mixins/animations";
@import "src/theme/mixins/fonts";


.apartmentCard {
  position: relative;
  background-color: var(--ion-background-color);
  display: block;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  width: 100%;
  text-align: left;
  border-radius: var(--wohnsinn-border-radius);
  transition: box-shadow .3s, border-color .3s;
  border: 2px solid var(--ion-background-color);
  @include pxToRem(padding, 8);

  @include for-min-size(md) {
    &:hover {
      border: 2px solid var(--ion-color-primary);
    }
  }

  .link {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .icon {
    position: absolute;

    @include pxToRem(width, 32);
    @include pxToRem(top, 8);
    @include pxToRem(right, 8);
  }

}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  transition: border .3s;
  @include pxToRem(padding-top, 12);
  @include pxToRem(padding-bottom, 12);

  .bottom {
    @include pxToRem(margin-top, 12);
  }
}

.image {
  z-index: 2;
  overflow: hidden;
  border-radius: var(--wohnsinn-border-radius);

  .placeholder {
    width: 100%;
    aspect-ratio: 3/2;
  }
}


.statusBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include text-small;
  @include pxToRem(margin-top, 12)
}


.publishedState {
  display: flex;
  align-items: center;
  @include pxToRem(gap, 4);

  svg {
    color: var(--ion-color-danger);
  }

  &.isPublished {
    svg {
      color: var(--ion-color-success);
    }
  }
}

.applicationsCountWrapper {
  z-index: var(--z-index-sticky);
  position: absolute;
  @include pxToRem(top, 18);
  @include pxToRem(left, 16);
}

.notificationsWrapper {
  z-index: var(--z-index-sticky);
  position: absolute;
  @include pxToRem(top, -20);
  @include pxToRem(right, 15);

  .notificationsBadge {
    border-radius: 9999px;
    background-color: var(--ion-color-danger);
    display: flex;
    justify-content: center;
    align-items: center;
    @include pxToRem(width, 28);
    @include pxToRem(height, 28);
  }
}
