@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

@mixin wrapperLayout {
  background-color: var(--ion-background-color);
  overflow: hidden;

  @include for-min-size(md) {
    top: var(--wohnsinn-desktop-header-height);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@mixin apartmentLayout($with-buttons: true) {
  width: 100%;
  @include pxToRem(padding-bottom, 16);

  @if  $with-buttons {
      @include pxToRem(padding-bottom, 112);
  }


  @include for-min-size(md) {
    height: 100%;
    overflow-y: scroll;
    border-right: 1px solid var(--wohnsinn-border-color);
    @include pxToRem(width, 500);
  }
}

@mixin mapLayout {
  display: none;

  @include for-min-size(md) {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    @include pxToRem(left, 500);
  }

}

@mixin ratingsLayout {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: var(--z-index-fixed);
  overflow: hidden;

  @include for-min-size(md) {
    @include pxToRem(width, 500);
  }
}
