@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  z-index: 1;
  border-top: 1px solid var(--wohnsinn-border-color);
  background-color: var(--ion-background-color);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  @include pxToRem(padding, 8);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-min-size(md) {
    @include pxToRem(padding, 16);
    @include pxToRem(padding-top, 16);

  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column-reverse;
  @include pxToRem(gap, 16);

  @include for-min-size(md) {
    flex-direction: row;
    display: flex;
    justify-content: space-between ;
  }
}

.flipButtons {
  @include for-min-size(md) {
    flex-direction: row-reverse;
  }
}
