.background {
  background-color: var(--ion-color-tertiary);
}

.imageWrapper {
  position: relative;
  min-height: 230px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image.loaded {
  opacity: 1;
}
