@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  text-align: center;
  @include pxToRem(margin-top, 32);
  @include pxToRem(margin-bottom, 32);

  .input {
    margin: auto;
    width: 100%;
    background-color: var(--ion-background-color);
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    line-height: 0;
    text-align: center;
    font-weight: bold;
    @include pxToRem(font-size, 36);
    @include pxToRem(letter-spacing, 32);
    @include pxToRem(text-indent, 32);
    @include pxToRem(padding-top, 12);
    @include pxToRem(padding-bottom, 12);

    @include for-min-size(sm) {
      @include pxToRem(max-width, 250);
    }
  }
}
