@import "src/theme/mixins/utils";

.wrapper {
  display: flex;
  @include pxToRem(gap, 2);

  .button {
    background-color: var(--ion-background-color);
    border: 1px solid var(--wohnsinn-border-color);
    border-right: none;
    color: var(--ion-color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--wohnsinn-border-radius) 0 0 var(--wohnsinn-border-radius);
    transition: background-color .4s;
    @include pxToRem(height, 40);
    @include pxToRem(width, 60);

    &:hover {
      background-color: var(--ion-color-tertiary);
    }

    &.right {
      border-radius: 0 var(--wohnsinn-border-radius) var(--wohnsinn-border-radius) 0;
      border-left: none;
      border-right: 1px solid var(--wohnsinn-border-color);
    }
  }

  .input {
    width: 100%;
    text-align: center;
    border: 1px solid var(--wohnsinn-border-color);
    background-color: var(--ion-background-color);
  }
}
