@import "src/theme/mixins/utils";

.wrapper {
  position: relative;
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding, 16);
}

.animationWrapper {
  @include pxToRem(padding-top, 50);
  @include pxToRem(height, 100);
}

.link {
  color: var(--ion-color-primary);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.copyButtonWrapper {
  display: flex;
  align-items: center;
  word-break: break-word;
  @include pxToRem(gap, 12);
  @include pxToRem(margin-bottom, 16);

  .actions {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 16)
  }

  .button {
    &:hover {
      color: var(--ion-color-primary);

      svg {
        color: var(--ion-color-primary);
      }
    }
  }
}
