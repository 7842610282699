@import "src/theme/mixins/utils";

.button {
  place-content: start;
 @include pxToRem(border-radius, 10);

 &:hover {
  background-color: var(--ion-color-tertiary);

 }

 .icon{
  color: var(--ion-text-color-accent);
  justify-content: start;
 }
}

.menuItem {
 display: flex;
 @include pxToRem(gap, 8)
}
