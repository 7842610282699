@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.stickyToggle {
  position: sticky;
  margin: auto;
  z-index: 1;
  border-radius: 9999px;
  width: 100%;
  @include pxToRem(top, 64);
  @include boxShadow;

  @include for-min-size(md) {
    @include pxToRem(top, 84);
    @include pxToRem(width, 580);
  }
}
