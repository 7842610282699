@import "src/theme/mixins/utils";

.titleWrapper {
  @include pxToRem(margin-bottom, 24);
}

.login {
  display: flex;
  @include pxToRem(gap, 6);

  justify-content: center;
  background: transparent;
}
