@import "../../../theme/mixins/responsive";
@import "../../../theme/mixins/utils";
@import "../../../theme/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--wohnsinn-mobile-header-height);
  background-color: var(--ion-background-color);
  border-bottom: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-min-size(md) {
    height: var(--wohnsinn-desktop-header-height);
  }


  .left {
    height: 100%;
    display: flex;
    align-items: center;
    @include pxToRem(gap, 24);

    .megaMenu {
      height: 100%;
      display: none;

      @include for-min-size(lg) {
        display: block;
      }
    }
  }
}


.appLogo {
  background-repeat: no-repeat;

  @include pxToRem(margin-top, 13);
  @include pxToRem(margin-bottom, 13);
  @include pxToRem(height, 20);
  @include pxToRem(width, 120);

  &.wogeLogo {
    background-repeat: no-repeat;
    background-size: contain;
    @include pxToRem(margin-top, 0);
    @include pxToRem(margin-bottom, 0);

    @include for-min-size(lg) {
      @include pxToRem(height, 40);
      @include pxToRem(width, 250);
    }

  }
}

.accountSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include pxToRem(gap, 10);
  @include pxToRem(width, 230);
}

.buttonWrapper {
  display: none;

  @media screen and (min-width: 1312px) {
    display: flex;
    @include pxToRem(gap, 10);
  }
}
