@import "src/theme/mixins/utils";

.selectWrapper {
  position: relative;

  .placeholder {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    @include pxToRem(padding-top, 8);
    @include pxToRem(padding-bottom, 8);
    @include pxToRem(padding-left, 14);
  }
}
