@import "src/theme/mixins/utils";

.wrapper {
  background-color: var(--ion-color-primary);
  border-radius: 9999px;
  @include pxToRem(padding, 16);

  .option {
    width: 50%;
    text-align: center;
    transition: color .2s ease-in;


    span {
      color: rgb(0 0 0 / 50%);
    }

    &:hover {
      span {
        color: var(--ion-text-color);
      }
    }

    &.active {
      span {
        color: var(--ion-text-color);
      }
    }

    &:first-of-type {
      border-right: 1px solid white;
    }
  }
}
