@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.image {
  position: relative;
  overflow: hidden;

  &.reelsView {
    height: 100%;
  }

  @include for-min-size(sm) {
    height: auto;
    border-radius: var(--wohnsinn-border-radius);
  }

  .placeholder {
    object-fit: cover;

    @include for-max-size(sm) {
      &.reelsView {
        height: 100%;
      }
    }

    @include for-min-size(sm) {
      aspect-ratio: 3/2;
    }
  }
}

