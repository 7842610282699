@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.view {
  display: grid;
  grid-template-columns: 1fr;
  @include pxToRem(gap, 16);

  @include for-min-size(sm) {
    grid-template-columns: 1fr 1fr;
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-right, 16);
  }

  @include for-min-size(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .loadingApartmentCard {
    @include for-min-size(sm) {
      border-radius: var(--wohnsinn-border-radius);
      border: 1px solid var(--wohnsinn-border-color);
      padding: 10px;
    }

    .image {
      width: 100%;
      aspect-ratio: 2/2.5;
      @include pxToRem(margin-bottom, 16);


      @include for-min-size(sm) {
        aspect-ratio: 3/2;
        border-radius: var(--wohnsinn-border-radius);
        border: 1px solid var(--wohnsinn-border-color);
        padding: 10px;
      }
    }

    .content {
      @include for-max-size(sm) {

      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-bottom, 16);
      }
    }

    .text {
      @include pxToRem(border-radius, 5);
      @include pxToRem(margin-top, 10);
      @include pxToRem(height, 20);
    }

    .text,
    .image {
      @include loadingSkeleton;
    }
  }
}

