@import "src/theme/mixins/fonts";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

$lightshow-arrow-width: 2rem;
$lightshow-arrow-width-md: 3rem;
$lightshow-arrow-width-lg: 5rem;

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 90%);
  z-index: var(--z-index-lightshow-backdrop);
}


.lightShowWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-lightshow);
  width: 100%;
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
}

.lightShowHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 98vw;
  margin-left: auto;
  margin-right: auto;
  @include pxToRem(margin-bottom, 8);

  @include for-min-size(lg) {
    max-width: 50vw;
  }
}

.title {
  @include text-large("bold");

  color: var(--ion-color-light);
}

.arrow {
  position: absolute;
  z-index: var(--z-index-modal);
  top: 50%;
  height: 100%;
  transform: translateY(calc(-50% - 25px));
  width: $lightshow-arrow-width;
  color: var(--ion-color-light);


  @include for-min-size(md) {
    width: $lightshow-arrow-width-md;
  }

  @include for-min-size(md) {
    width: $lightshow-arrow-width-lg;
  }

  &:hover {
    color: var(--ion-color-primary);
  }

  &.disabled {
    opacity: 0;
  }

  &.arrowPrev {
    left: 0;
  }

  &.arrowNext {
    right: 0;
  }

  svg {
    @include for-min-size(md) {
      @include pxToRem(width, 25);
      @include pxToRem(height, 25);
    }

    @include for-min-size(lg) {
      @include pxToRem(width, 33);
      @include pxToRem(height, 33);
    }
  }
}

.swiperWrapper {
  position: relative;
  max-width: 98vw;
  margin-left: auto;
  margin-right: auto;


  @include for-min-size(lg) {
    max-width: 50vw;
  }

}

.slides {
  .slide {
    max-height: 70vh;
    max-width: 100%;
    width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: black;
    display: flex;
    overflow: hidden;
    aspect-ratio: 3 / 2;

    @include for-min-size(lg) {
      max-width: 50vw;
    }
  }
}

.video {
  margin: 0 auto;
  max-height: 100%;
}

.thumbnails {
  max-width: 98vw;
  @include pxToRem(margin-top, 5);
  @include pxToRem(height, 50);

  box-sizing: border-box;

  @include for-min-size(lg) {
    @include pxToRem(height, 100);

    max-width: 50vw;
  }

  .slide {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
    cursor: pointer;
  }

}
