.virtualTourStatus {
  display: flex;
  align-items: center;
  gap: .25rem;

  &.green {
    svg {
      color: var(--ion-color-success);
    }
  }

  &.red {
    svg {
      color: var(--ion-color-danger);
    }
  }
}
