@import "src/theme/mixins/utils";

.tabs {
  @include pxToRem(margin-top, 16);
  @include pxToRem(margin-bottom, 16);

  span:global(.MuiTabs-indicator) {
    display: none;
  }

  .tab {
    border-bottom: 2px solid transparent;;
    text-transform: none;
    transition: background-color .2s;
    @include pxToRem(padding, 8);
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-right, 16);

    &:hover {
      background-color: var(--ion-color-tertiary);
    }

    &.active {
      border-bottom-color: var(--ion-color-primary);
    }

  }


  .badge {
    display: inline-block;
    background-color: var(--ion-color-danger);
    border-radius: 9999px;
    @include pxToRem(width, 10);
    @include pxToRem(height, 10);
  }
}
