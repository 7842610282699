@import "src/theme/mixins/utils";

.menu {
  height: 0;
  overflow: hidden;
  transition: height .2s ease-out;

  &.show {
    @include pxToRem(height, 250);
  }
}


.parentItem > svg,
.parentItem > span {
  transition: color .2s ease-in;
}

.linkWithIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(gap, 12);
  @include pxToRem(padding, 12);
  @include pxToRem(padding-left, 0);
  @include pxToRem(margin-bottom, 8);

  > span {
    transition: color .2s ease-in;
  }

  &.active {
    > span {
      color: var(--ion-color-primary);
    }
  }
}

.parentItem {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  @include pxToRem(height, 50);

  &:hover {
    > svg,
    > span {
      color: var(--ion-color-primary);
    }
  }
}


