@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  width: 100%;
  position: relative;
  transition: height .2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include for-max-size(sm) {
    box-shadow: 0 3px 8px -3px rgb(0 0 0 / 57%);
  }

  @include for-max-size(sm) {
    height: calc(100vh - 110px);
    display: flex;
    flex-direction: column;
    @include pxToRem(margin-bottom, 24);
  }

  @include for-min-size(sm) {
    background-color: var(--ion-background-color);
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    transition: all .2s;
    height: 100%;
    @include pxToRem(padding, 16);

    padding-bottom: 0;

    &:hover {
      border-color: var(--ion-color-primary);
      @include boxShadow;
    }
  }
}
