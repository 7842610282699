@import "src/theme/mixins/utils";

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-background-color);
  @include pxToRem(padding, 16);

  .header {
    display: flex;
    justify-content: flex-end;
  }

  .body {
    flex: 1;
  }

  .infos,
  .notificationSettings {
    @include pxToRem(margin-top, 16);
    @include pxToRem(margin-bottom, 24);
  }
}
