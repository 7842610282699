@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.editor-disabled,
.editor-input {
  min-height: 2.88rem;
  background-color: var(--ion-background-color);
  border: none;
  border-radius: 10px;
  @include pxToRem(padding, 5);

  &.large {
    height: 8.6rem;
    overflow: auto;
    @include pxToRem(padding, 10);

    @include for-min-size(md) {
      @include pxToRem(padding, 15);
    }
  }

  &.extraLarge {
    height: 16rem;
    overflow: auto;
    @include pxToRem(padding, 10);

    @include for-min-size(md) {
      @include pxToRem(padding, 15);
    }
  }
}

.editor-disabled {
  height: 40px;
  cursor: not-allowed;
}

.editor-paragraph {
  width: 100%;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}
