$breakpoints: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1480px,
);

@mixin for-min-size($breakpoint) {
  @each $mediaType, $width in $breakpoints {
    @if $mediaType == $breakpoint {
      @media (min-width: $width) {
        @content;
      }
    }
  }
}

@mixin for-max-size($breakpoint) {
  @each $mediaType, $width in $breakpoints {
    @if $mediaType == $breakpoint {
      @media (max-width: calc($width - 1px)) {
        @content;
      }
    }
  }
}
