@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.list {
  display: flex;
  flex-direction: column;

  @include for-min-size(md) {
    @include pxToRem(gap, 6);
  }
}

.item {
  display: flex;
  align-items: center;
  @include pxToRem(gap, 12);
  @include pxToRem(margin-bottom, 20);
}
