@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.radioButtonWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .options {
    width: 100%;
    display: grid;
    @include pxToRem(gap, 8);

    &.TWO {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.THREE {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &.FIVE {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

}


.radio {
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-background-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  @include pxToRem(height, 44);

  &:hover {
    border-color: var(--ion-color-primary);
    background-color: var(--ion-color-tertiary);
  }

  &.active {
    background-color: var(--ion-color-primary);
    border: 1px solid var(--ion-color-primary);

    &:hover {
      background: var(--ion-color-primary-shade);
    }
  }
}

.helperText {
  @include pxToRem(margin-top, 8);
}
