@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.accordion {
  overflow: hidden;
  background-color: transparent;
  @include boxShadow;

  .optionWrapper {
    box-shadow: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
    transition: background-color .3s;

    &:hover,
    &.activeTab {
      background-color: var(--ion-color-tertiary);
      margin-top: 0;
      margin-bottom: 0;
    }

    .label {
      display: flex;
      align-items: center;
      @include pxToRem(gap, 16);

      h1 {
        word-wrap: break-word;
        line-break: initial;
        word-break: normal;
        white-space: initial;
      }

      svg {
        color: var(--ion-color-primary);
      }

    }

  }

  .content {
    @include pxToRem(padding, 16);

    padding-top: 0;

    .image {
      margin-left: auto;
      margin-right: auto;

      &.hideOnDesktop {
        display: block;

        @include for-min-size(lg) {
          display: none;
        }
      }
    }
  }


  &.isLanding {
    box-shadow: none;
  }

}
