@import "src/theme/mixins/utils";

.wrapper {
  position: relative;

  .swiperNav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    opacity: 0.5;

    svg {
      color: var(--ion-text-color-accent);
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }
}
