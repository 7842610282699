@import "src/theme/mixins/utils";

.backButton {
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include pxToRem(margin-right, 10);
  @include pxToRem(height, 30);

  &:hover {
    background-color: var(--ion-color-tertiary);
  }

  svg {
    color: var(--ion-text-color);
  }
}
