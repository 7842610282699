@import "src/theme/mixins/utils";

.wrapper {
  background-color: var(--ion-background-color);
  @include pxToRem(margin-top, 8);
  @include pxToRem(margin-bottom, 8);
}

.uploadItem {
  cursor: pointer;
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  background-color: var(--ion-background-color);
  overflow: hidden;
  @include pxToRem(margin-top, 8);
  @include pxToRem(padding-bottom, 5);
  @include pxToRem(padding-top, 5);
  @include pxToRem(padding-right, 15);
  @include pxToRem(padding-left, 15);

  .label {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 12);
  }

  .uploadItemContent {
    display: flex;
    justify-content: space-between;
  }

  .buttonWrapper {
    display: flex;
    @include pxToRem(gap, 16);
  }
}
