@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.organizationImage {
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);

  @include pxToRem(margin-bottom, 24);

  @include for-min-size(sm) {
    @include pxToRem(max-height, 500);
    @include pxToRem(max-width, 500);
  }
}
