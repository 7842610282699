@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.optionListWrapper {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  @include pxToRem(gap, 16);

  &.twoColumns {
    grid-template-columns: 1fr 1fr;
  }

  .optionListItem {
    display: flex;
    background-color: var(--ion-background-color);
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    overflow: hidden;
    cursor: pointer;
    transition: all .3s;
    @include pxToRem(padding, 16);
    @include pxToRem(gap, 16);

    &.withImage {
      background-size: cover;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: 0;
      @include pxToRem(height, 150);

      @include for-min-size(md) {
        @include pxToRem(height, 200);
      }
    }


    .textWithImage {
      background-color: var(--ion-background-color);
      @include pxToRem(padding, 8);
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
    }

    &.error {
      border-color: var(--ion-color-danger);
    }

    &.disabled {
      pointer-events: none;
      opacity: .5;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9999px;
      background-color: var(--ion-color-tertiary);
      @include pxToRem(height, 32);
      @include pxToRem(width, 32);

      svg {
        color: var(--ion-color-primary);
      }
    }
  }

  .input {
    &:hover,
    &:checked {
      + .optionListItem {
        border: var(--ion-color-primary) 1px solid;
        background-color: var(--ion-color-tertiary);

        .textWithImage {
          background-color: var(--ion-color-tertiary);
        }
      }
    }
  }
}

