@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;
  background-color: var(--ion-color-light-shade);
  height: 100%;
  display: flex;
  flex-direction: column;

  .image {
    width: 100%;
    object-fit: cover;
    object-position: top;
    @include pxToRem(height, 270);
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    @include pxToRem(padding, 18);
    @include pxToRem(gap, 48);

    @include for-min-size(md) {
      @include pxToRem(padding, 24);
    }

    .footer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @include pxToRem(gap, 8);

      @include for-min-size(md) {
        align-items: flex-end;
        flex-direction: row;
      }
    }
  }

  &.wide {
    @include for-min-size(md) {
      flex-direction: row;
    }

    .image {
      @include pxToRem(height, 270);

      @include for-min-size(md) {
        height: unset;
        @include pxToRem(max-width, 350);
      }
    }
  }
}
