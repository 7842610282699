@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.menu {
  display: flex;
  @include pxToRem(gap, 40);

  .linkElement {
    border-bottom: 2px solid;
    border-top: 2px solid;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @include pxToRem(gap, 8);
    @include pxToRem(padding-top, 6);
    @include pxToRem(padding-bottom, 6);

    .notificationsWrapper {
      position: absolute;
      @include pxToRem(right, -14);
      @include pxToRem(top, -2);
    }
  }


  .link {
    text-decoration: none;
    color: var(--ion-text-color);
    cursor: pointer;

    &.active,
    &:hover {
      .menuItemLabel {
        color: var(--ion-color-primary);
      }

      .linkElement {
        border-bottom-color: var(--ion-color-primary);
      }
    }
  }
}
