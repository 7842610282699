@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

@mixin gridTemplate {
  @include for-min-size(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-min-size(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include for-min-size(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.wrapper {
  min-height: 100%;
  @include pxToRem(margin-bottom, 60);

  @include for-min-size(sm) {
    @include pxToRem(margin-top, 40);
  }

  @include for-max-size(sm) {
    background-color: var(--ion-color-light);
  }

  @include for-min-size(md) {
    margin-top: 0;
  }

  .saveSearch {
    height: 0;
    position: sticky;
    width: 100%;
    z-index: var(--z-index-fixed);
    display: flex;
    justify-content: center;
    top: 90%;

    @include for-min-size(lg) {
      @include pxToRem(bottom, 80);
    }
  }

  .content {
    width: 100%;
    @include pxToRem(padding-top, 42);

    @include for-min-size(sm) {
      @include pxToRem(padding-top, 8);
    }

    @include for-min-size(md) {
      padding-top: 0;
    }

    .moreFilters {
      display: none;
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-bottom, 16);

      @include for-min-size(md) {
        display: block;
        @include pxToRem(gap, 10);
      }
    }

    .cardParent > div,
    .card {
      height: 100%;
    }
  }
}
