@import "src/theme/mixins/utils";

.wrapper {
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-color-tertiary);
  transition: box-shadow .3s;
  @include pxToRem(padding, 20);
  @include pxToRem(margin-bottom, 12);
  @include boxShadow;

  &:hover {
    box-shadow: none;
  }

  .image {
    aspect-ratio: 3/4;
    object-fit: cover;
    border-radius: var(--wohnsinn-border-radius);
    @include pxToRem(margin-bottom, 16);
  }
}
