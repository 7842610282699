@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.chatView {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.list {
  @include pxToRem(padding, 8);
}

.chatMessagesList {
  background-color: #F6F8FC;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  @include pxToRem(padding, 16);
  @include hideScrollBars;
}

.emptyApplicationsWrapper {
  @include pxToRem(margin-top, 100);
}

.chatInputWrapper {
  @include pxToRem(padding, 8);

  form {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 8);
  }

  .sendButton {
    @include pxToRem(width, 44);
    @include buttonAnimation;
  }
}

.dateWrapper {
  text-align: center;
  @include pxToRem(margin-bottom, 8);
  @include pxToRem(margin-top, 8);
}

.pageTitle {
  @include pxToRem(margin-top, 20);

  @include for-min-size(md) {
    display: none;
  }
}

.loadingMessage,
.loadingChatInputPlaceholder {
  width: 100%;
  @include loadingSkeleton;
}

.loadingMessagesListPlaceholder {
  @include pxToRem(padding, 16);

  .loadingMessage {
    width: calc(100% - 80px);
    border-radius: var(--wohnsinn-border-radius);
    float: left;
    @include pxToRem(height, 30);
    @include pxToRem(margin-bottom, 15);

    &.right {
      float: right;
    }
  }
}
