@import "../../../theme/mixins/responsive";
@import "../../../theme/mixins/utils";

.informationWrapper {
  width: 100%;
  @include pxToRem(padding-bottom, 8);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(padding-top, 16);

  &.isUnpublished {
    opacity: .6;
    pointer-events: none;
    cursor: pointer;
  }

}

.apartmentImage {
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;

  .placeHolder {
    height: 300px;
    background-position: center;
    background-size: cover;
  }
}

.mapPlaceHolder {
  background: linear-gradient(0deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 30%)), url("../../../../public/assets/images/map-placeholder.png") center;
  background-size: cover;
  width: 100%;
  @include pxToRem(height, 250);
}

.mapCloseButton {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--ion-color-light);;
}

.closeMapButton {
  position: absolute;
  z-index: var(--z-index-base);
  @include pxToRem(left, 4);
  @include pxToRem(top, 4);
}

.openMapButton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--z-index-base);
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: rgb(0 0 0 / 40%);
  }
}

.mapPreview {
  position: relative;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;

  @include for-min-size(md) {
    display: none;
  }
}

.desktopMap {
  width: 100%;
  height: 500px;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;

  @include for-max-size(md) {
    display: none;
  }
}

.apartmentCard {
  position: relative;
}

.informationSection {
  display: flex;
  flex-direction: column;
  @include pxToRem(margin-top, 36);
  @include pxToRem(gap, 16);

  @include for-min-size(sm) {
    @include pxToRem(margin-top, 48);
  }
}

.layout {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  @include pxToRem(gap, 5);

  .layoutLink {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }

    display: flex;
    @include pxToRem(gap, 5);
  }
}
