@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";


.buttonWrapper {
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 10);
  @include pxToRem(margin-top, 16);
}

.notificationsWrapper {
  @include pxToRem(margin-left, 10);
}

.generalLinks {
  a:hover p {
    color: var(--ion-color-primary) !important;
  }
}

.secondaryMenu {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.menu {
  .link {
    position: relative;
    display: flex;
    align-items: center;
    @include pxToRem(padding-top, 10);
    @include pxToRem(padding-bottom, 10);

    .arrow {
      position: absolute;
      transition: transform .2s;
      @include pxToRem(right, 15);
    }

    &:hover {
      span {
        color: var(--ion-color-primary);
      }

      .notificationsWrapper span {
        color: var(--ion-background-color);
      }

      .arrow {
        transform: translateX(5px);
      }
    }
  }
}

