@import "src/theme/mixins/utils";

.wrapper {
  height: 100%;
}

.progressBar {
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  position: relative;
  @include pxToRem(height, 35);

  .progressBarProgress {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: var(--ion-color-secondary);
    width: 0;
    border-radius: var(--wohnsinn-border-radius);
    z-index: 0;
  }

  .progressBarContent {
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    left: 10px;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    @include pxToRem(left, 10);
  }
}
