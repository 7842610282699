@import "src/theme/mixins/utils";

.badge {
  display: inline-block;
  font-weight: 600;
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding-top, 3);
  @include pxToRem(padding-bottom, 1);
  @include pxToRem(padding-left, 6);
  @include pxToRem(padding-right, 6);
  @include pxToRem(font-size, 16);

  &.PRIMARY {
    background: var(--color-primary--light);
    color: var(--color-primary--strong);
  }

  &.DANGER {
    background: var(--ion-color-danger-tint);
    color: var(--color-red--very-strong);
  }

  &.LIGHT {
    background: var(--ion-background-color);
  }

  &.SUCCESS {
    background: var(--ion-color-success-tint);
    color: var(--ion-color-success-shade);
  }

  &.DISABLED {
    background: var(--ion-color-light-shade);
    color: var(--ion-color-light-tint);
  }

  .PRIMARY {
    color: var(--color-primary--strong);
  }

  .DANGER {
    color: var(--color-red--very-strong);
  }

  .SUCCESS {
    color: var(--ion-color-success-shade);
  }

  .DISABLED {
    color: var(--ion-color-medium);
  }

  .icon {
    cursor: pointer;
    @include pxToRem(margin-right, 5);
  }
}

