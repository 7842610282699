@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.container {
  width: 100vw;
  height: 100%;
  position: relative;

  @include for-min-size(md) {
    @include pxToRem(max-width, 900);
  }

  @include for-min-size(lg) {
    @include pxToRem(max-width, 1000);
  }


  .header {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    align-items: center;
    background-color: var(--ion-background-color);
    border-bottom: 1px solid var(--wohnsinn-border-color);
    justify-content: space-between;
    @include pxToRem(height, 65);
    @include pxToRem(padding, 16);
    @include pxToRem(padding-top, 8);
    @include pxToRem(padding-bottom, 8);



    @include for-min-size(md) {
      @include pxToRem(max-width, 900);
    }

    @include for-min-size(lg) {
      @include pxToRem(max-width, 1000);
    }

    .left {
      display: flex;
      align-items: center;
      @include pxToRem(gap, 16);
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    overflow: hidden;
    @include pxToRem(top, 65);


    .scrollableContent {
      flex: 1;
      overflow-y: auto;

      &.chat {
        @include for-min-size(md) {
          @include pxToRem(max-width, 500);
        }

        @include for-min-size(lg) {
          @include pxToRem(width, 600);
        }
      }

      &.applicant {
        border-left: 1px solid var(--wohnsinn-border-color);
          @include pxToRem(max-width, 400);

        @include for-min-size(lg) {
          @include pxToRem(width, 300);
        }
      }
    }
  }
}


