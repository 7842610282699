@import "src/theme/mixins/utils";

.filterSection {
  @include pxToRem(margin-bottom, 32);

  &:last-of-type {
    margin-bottom: 0;
  }

  > p:first-of-type {
    @include pxToRem(margin-bottom, 8);
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 8);
}
