@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.logoWrapper {
  text-align: center;
  @include pxToRem(margin-bottom, 72);

  .buttonWrapper {
    @include for-max-size(md) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--ion-background-color);
    }
  }
}

.organizationImage {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;

  @include pxToRem(margin-bottom, 24);
  @include pxToRem(max-width, 300);
  @include pxToRem(max-height, 300);
}

.exitButton {
  @include pxToRem(margin-top, 24);
}

.copyButtonWrapper {
  display: flex;
  @include pxToRem(gap, 10);

  .button {
    &:hover {
      color: var(--ion-color-primary);

      svg {
        color: var(--ion-color-primary);
      }
    }
  }
}
