@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  @include pxToRem(margin-top, 32);
  @include pxToRem(margin-bottom, 16);

  @include for-min-size(md) {
    @include pxToRem(margin-top, 32);
  }

  &.first {
    margin-top: 0;
  }
}
