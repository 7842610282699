@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.content {
  width: 100%;
  background-color: var(--ion-background-color);
  @include pxToRem(padding, 16);

  padding-top: 0;


  @include for-min-size(sm) {
    @include pxToRem(padding-top, 8);

    padding-left: 0;
    padding-right: 0;
  }

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include pxToRem(height, 48);
  }
}
