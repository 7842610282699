@import "src/theme/mixins/utils";

.wrapper {
  text-align: center;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .image {
    margin: 0 auto;
    width: 100%;
    @include pxToRem(max-width, 350);
    @include pxToRem(margin-bottom, 24);
  }

}
