@import "src/theme/mixins/utils";

.wrapper {
  position: relative;

  .image {
    object-fit: cover;
    aspect-ratio: 5/6;
  }

  .blurred {
    border-top: 1px solid rgb(255 255 255 / 50%);
    background-color: rgb(255 255 255 / 30%);
    backdrop-filter: blur(12px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .content {
      @include pxToRem(padding, 24);

      .social {
        color: var(--ion-text-color-light);

        &:hover {
          color: var(--ion-color-primary);
        }
      }

      .position {
        @include pxToRem(margin-top, 8);
        @include pxToRem(margin-bottom, 16);
      }
    }
  }
}
