@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.pageTitleActionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @include pxToRem(gap, 16);

  .videoUpload {
    display: none;

    @include for-min-size(lg) {
      display: block;
    }
  }
}

.mediaUploadWrapper {
  display: flex;
  flex-direction: column !important;
  @include pxToRem(gap, 8);

  @include for-max-size(md) {
    background-color: var(--ion-color-light);
    position: sticky;
    bottom: 0;
    @include pxToRem(padding-top, 16);
    @include pxToRem(padding-bottom, 16);
  }

  @include for-min-size(md) {
    flex-direction: row !important;
    justify-content: flex-end;
    margin-top: 24px !important;
    @include pxToRem(gap, 12);
  }

  .hideMobile {
    display: none;

    @include for-min-size(md) {
      display: block;
    }
  }

  .nextButtonMobile {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;

    @include for-min-size(md) {
      display: none;
    }
  }

}

.videoInfoWrapper {
  display: flex;
  flex-direction: column;
  @include pxToRem(margin-top, 16);
  @include pxToRem(margin-bottom, 16);
  @include pxToRem(padding-left, 16);
  @include pxToRem(gap, 10);
}

.exampleVideoWrapper {
  background: var(--ion-color-dark);
  border-radius: var(--wohnsinn-border-radius);

  .exampleVideo {
    overflow: hidden;
    border-radius: var(--wohnsinn-border-radius);
    margin: 0 auto;
    max-width: 150px;

    @include for-min-size(md) {
      max-width: 200px;
    }
  }
}

.thumbnailButton {
  display: none;
  cursor: pointer;
  position: relative;
  width: 100%;
  aspect-ratio: 2 / 1;
  border-radius: var(--wohnsinn-border-radius);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include for-min-size(md) {
    display: block;
  }

  .playButton {
    background-color: var(--ion-background-color);
    border-radius: 9999px;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include pxToRem(width, 50);
    @include pxToRem(height, 50);

    svg {
      color: var(--ion-color-primary);
    }
  }

  @include for-min-size(md) {
    aspect-ratio: 4 / 2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(0 0 0 / 0%);
    transition: background-color .2s;
    border-radius: var(--wohnsinn-border-radius);
  }


  &:hover {
    &::after {
      background-color: rgb(0 0 0 / 15%);
    }
  }

  .button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.mobilePlayButton {
  @include pxToRem(margin-top, 16);
  @include for-min-size(md) {
    display: none;
  }
}
