@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  display: flex;
  align-items: center;

  @include for-max-size(md) {
    display: none;
  }
}
