@import "src/theme/mixins/responsive";
@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.modalWrapper {
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @include pxToRem(padding-top, 16);

  .modalHeader {
    position: relative;
    text-align: center;
    @include pxToRem(margin-bottom, 15);
    @include pxToRem(padding-left, 16);
    @include pxToRem(padding-right, 16);

    @include for-min-size(lg) {
      @include pxToRem(margin-bottom, 20);
    }

    .icon {
      margin: 0 auto;
      @include pxToRem(margin-bottom, 16);
      @include pxToRem(max-height, 48);
      @include pxToRem(max-width, 120);
    }

    .iconWrapper {
      border-radius: var(--wohnsinn-border-radius);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      @include pxToRem(margin-bottom, 16);
      @include pxToRem(height, 48);
      @include pxToRem(width, 48);

      .icon {
        color: var(--ion-text-color);
        margin: 0;
      }
    }

    .button {
      color: var(--ion-text-color);
      position: absolute;
      @include pxToRem(top, -8);
    }

    .close {
      position: absolute;
      @include pxToRem(right, 16);

      .closeIcon {
        @include pxToRem(font-size, 28);
      }
    }

    .back {
      @include pxToRem(left, 12);
    }

    .titleWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .modalChildren {
    max-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .childrenWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-bottom, 16);

      @include for-min-size(md) {
        @include pxToRem(min-width, 500);
      }

      &.noContentPadding {
        padding: 0;
      }

    }

    .modalButtons {
      border-top: 1px solid var(--wohnsinn-border-color);
      z-index: var(--z-index-fixed);
      display: flex;
      flex-direction: column;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--ion-background-color);;
      position: sticky;
      @include pxToRem(padding, 16);
      @include pxToRem(gap, 8);
    }
  }
}
