@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";
@import "mapbox";


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

// hide default checkbox
.styled-checkbox {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  @include pxToRem(height, 1);

  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  @include pxToRem(width, 1);

  &:focus-visible {
    & + .checkbox {
      border-color: black;
    }
  }
}

/* IonAlert */
.alert-wrapper {
  min-width: 80vw !important;
  @include for-min-size(sm) {
    min-width: 22rem !important;
  }
}

.alert-button-role-destructive {
  color: var(--ion-color-danger);
}

.breadcrumb-active {
  color: var(--ion-color-primary);
  font-weight: bold;
}

.input, {
  --background: transparent;

  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);


  &:active,
  &:focus-within,
  &:focus {
    border: 1px solid var(--ion-color-primary);
  }
}


a:hover {
  cursor: pointer;
}
