@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include pxToRem(padding, 32);
  @include pxToRem(gap, 48);
}

.linksWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
}

.logosWrapper {
  width: 100%;
  @include for-min-size(md) {
    max-width: 25%;
  }
}

.links {
  display: flex;
  flex-direction: column;

  @include pxToRem(gap, 16);
}

.logoWrapper {
  display: flex;
  justify-content: space-between;

  @include pxToRem(gap, 16);
}

.bottomWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include pxToRem(padding, 32);
  @include for-max-size(md) {
    flex-direction: column-reverse;
    align-items: start;

    @include pxToRem(padding, 16);
  }
}
