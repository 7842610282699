@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

@mixin badgeStyle {
  border-radius: 9999px;
  background-color: var(--ion-color-danger);
  @include pxToRem(width, 8);
  @include pxToRem(height, 8);
}

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid var(--wohnsinn-border-color);
  transition: all .2s;
  @include pxToRem(height, 42);
  @include pxToRem(padding, 3);
  @include pxToRem(min-width, 42);
  @include pxToRem(gap, 8);
  @include pxToRem(border-radius, 50);

  &.withImg {
    min-width: unset;
    @include pxToRem(padding-right, 15);
  }

  &:hover {
    @include boxShadow;
  }

  svg {
    color: var(--ion-text-color);
  }

  .notification {
    position: absolute;
    @include pxToRem(right, 10);
    @include pxToRem(top, 10);
    @include badgeStyle;
  }

}
