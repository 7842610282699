@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  @include pxToRem(padding-top, 18);
  @include pxToRem(padding-bottom, 18);
  @include pxToRem(gap, 12);

  @include for-min-size(lg) {
    gap: 0;
    flex-direction: row;
    justify-content: space-between;
    @include pxToRem(padding-top, 50);
    @include pxToRem(padding-bottom, 50);
  }

  .column {
    padding: 0;
  }

  .title {
    width: 100%;
    display: flex;
    flex-direction: column;

    .badgeWrapper {
      display: inline-flex;
      @include pxToRem(gap, 10);

      align-items: center;
    }
  }

  .submitButton {
    display: none;

    @include for-min-size(lg) {
      display: flex;
      justify-content: flex-end;
      @include pxToRem(gap, 12);
    }
  }
}

.secondPageTitle {
  width: 100%;
  @include pxToRem(margin-top, 8);

  @include for-min-size(md) {
    width: 50%;
    @include pxToRem(margin-top, 12);
  }
}
