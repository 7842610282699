@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include pxToRem(gap, 24);
  @include pxToRem(margin-top, 24);
  @include pxToRem(min-height, 350);

  @include for-max-size(sm) {
    @include pxToRem(height, 450);
  }
}
