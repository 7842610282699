@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 10%);
  z-index: 1;
  display: none;

  &.show {
    display: block;
  }
}

.navigation {
  display: flex;
  align-items: center;
  height: 100%;
  @include pxToRem(gap, 45);

  @include for-max-size(md) {
    display: none;
  }

  .listItem {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .listItemText {
      display: flex;
      align-items: center;
      @include pxToRem(gap, 12);

      &:hover {
        color: var(--ion-color-primary);
      }
    }

    .subMenu {
      border-radius: var(--wohnsinn-border-radius);
      background-color: var(--ion-background-color);
      white-space: nowrap;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
      @include pxToRem(width, 280);

      @include pxToRem(padding, 12);
      @include boxShadow;

      &.show {
        opacity: 1; /* Opacity when visible */
        pointer-events: auto;
      }

      .linkWithIcon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: var(--wohnsinn-border-radius);
        transition: background-color .2s ease-in;
        @include pxToRem(gap, 12);
        @include pxToRem(padding, 12);
        @include pxToRem(padding-right, 12);
        @include pxToRem(margin-bottom, 8);

        &.active,
        &:hover {
          background-color: var(--ion-color-tertiary);
        }
      }
    }
  }
}

