@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.form-field {
  width: 100%;
}

.form-with-navigation {
  @include for-max-size(md) {
    @include pxToRem(padding-bottom, 72);
  }
  @include pxToRem(padding-bottom, 48);
}

input:disabled {
  background-color: var(--ion-color-light-shade);
}


input[type="radio"]:not(.default) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  transition: all .3s;

  &:focus-visible {
    & + .radio {
      border: 1px solid black;
    }
  }

  &:checked {
    margin: 1rem;
  }
}
