@import "src/theme/mixins/utils";

.wrapper {
  background-color: var(--ion-color-primary);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  @include pxToRem(width, 60);

  span {
    color: var(--ion-color-light);
    @include pxToRem(font-size, 36);
  }
}
