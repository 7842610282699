@import "src/theme/mixins/utils";

.wrapper {
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  .icon {
    margin-left: auto;
    margin-right: auto;
  }

  .content {
    margin-left: auto;
    margin-right: auto;
    @include pxToRem(max-width, 760);
  }

  .buttonWrapper {
    @include pxToRem(margin-top, 20);

    button {
      margin: 0 auto;
    }
  }
}
