@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ws-full-width-bg {
  background-color: var(--ion-color-secondary);
  @include full-width-background(secondary);

  &--tertiary {
    background-color: var(--ion-color-tertiary);
    @include full-width-background(tertiary);
  }

  &--green {
    background-color: var(--ion-color-success-tint);
    @include full-width-background(green);
  }

  &--white {
    background-color: var(--ion-color-light-tint);
    @include full-width-background(white);
  }

  &--dark {
    background-color: var(--ion-color-dark-shade);
    @include full-width-background(dark);
  }
}
