@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.banner {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: var(--wohnsinn-mobile-header-height);

  @include for-min-size(lg) {
    top: var(--wohnsinn-desktop-header-height);
  }
}

.bannerSpace {
  @include pxToRem(height, 60);

  @include for-min-size(md) {
    @include pxToRem(height, 40);
  }
}
