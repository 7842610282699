@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @include pxToRem(gap, 64);

  @include for-min-size(md) {
    flex-direction: row;
  }

  .content,
  .image {
    width: 100%;
    @include for-min-size(md) {
      width: 50%;
    }
  }

  .metricsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    @include pxToRem(gap, 64);
    @include pxToRem(margin-top, 32);

    @include for-min-size(md) {
      grid-template-columns: 1fr 1fr;
    }

    .metric {
      span {
        text-align: center;
      }

      display: flex;
      flex-direction: column;
    }
  }
}
