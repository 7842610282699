@import "src/theme/mixins/utils";

.video {
  background-color: black;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;
  @include pxToRem(max-height, 250);
}

.aboutMeText {
  font-weight: 300;
}
