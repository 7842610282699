@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.filterBar {
  border-bottom: 1px solid var(--wohnsinn-border-color);
  top: var(--wohnsinn-mobile-header-height);
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  width: 100%;
  background-color: var(--ion-background-color);
  transition: border .4s, top .2s, transform .4s;
  z-index: var(--z-index-fixed);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(padding-top, 4);
  @include pxToRem(padding-bottom, 4);

  @include for-min-size(sm) {
    display: block;
    border-bottom: none;
  }

  @include for-min-size(md) {
    padding-top: 0;
    padding-bottom: 0;
    transition: top .2s, padding .2s;
    @include pxToRem(top, 11);
    @include pxToRem(max-width, 400);
  }

  @include for-min-size(lg) {
    @include pxToRem(max-width, 500);
  }

  .filterButton {
    @include pxToRem(min-width, 40);
  }

  .wrapper {
    display: flex;
    flex-grow: 1;
    background-color: var(--ion-background-color);
    border: 1px solid var(--wohnsinn-border-color);
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow .2s;
    @include pxToRem(border-radius, 9999);
    @include pxToRem(padding, 3);

    &.active,
    &:hover {
      @include boxShadow($light: true);
    }

    .location {
      flex-grow: 1;
      transition: width .2s;
      overflow: hidden;
      white-space: nowrap;
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-top, 0);
      @include pxToRem(padding-bottom, 0);
      @include pxToRem(border-top-left-radius, 9999);
      @include pxToRem(border-bottom-left-radius, 9999);
      @include pxToRem(padding-left, 24);
      @include pxToRem(padding-right, 16);

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover,
      &.active {
        span {
          color: var(--ion-color-primary);
        }
      }
    }
  }
}
