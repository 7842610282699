.ql-container,
.ql-toolbar {
  background-color: var(--ion-background-color);
  border-color: var(--wohnsinn-border-color);
}

.ql-container {
  border-radius: var(--wohnsinn-border-radius);
}

.ql-toolbar {
  border-radius: var(--wohnsinn-border-radius) var(--wohnsinn-border-radius) 0 0;

  +.ql-container{
      border-radius: 0 0 var(--wohnsinn-border-radius) var(--wohnsinn-border-radius);
  }
}

