@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.drawer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 100px);
  position: relative;

  .ratingInfo {
    position: absolute;
    background-color: rgb(255 255 255 / 66%);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-index-fixed);

    svg {
      color: var(--ion-color-medium-tint);
    }
  }

  @include for-min-size(sm) {
    height: auto;
  }

  @include for-min-size(md) {
    @include pxToRem(width, 600);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include pxToRem(padding-top, 10);
    @include pxToRem(padding-bottom, 8);
    @include pxToRem(padding-right, 16);
    @include pxToRem(padding-left, 16);

    .buttonWrapper {
      display: flex;
      @include pxToRem(gap, 10);
    }
  }

  .body {
    flex: 1;
    overflow-x: hidden;
    position: relative;
    border-top: 1px solid var(--wohnsinn-border-color);
  }
}
