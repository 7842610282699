@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  max-width: 100%;
  position: relative;

  @media print {
    display: none;
  }
}

.hideNonPrint {
  width: 100vw;
  display: none;

  @media print {
    display: block;
  }
}
