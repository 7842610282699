@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";
@import "src/theme/mixins/apartment-map-layout";

.wrapper {
  @include wrapperLayout;
}

.apartment {
  @include apartmentLayout;
}

.map {
  @include mapLayout;
}

.ratings {
  @include ratingsLayout;
}

.applicationLink {
  padding: 1rem;
}

.notExistingApartmentWrapper {
  @include pxToRem(margin-top, 100);
}

.ratingButtons {
  @include for-min-size(md) {
    display: none
  }
}

.bouncingHeartsWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgb(0 0 0 / 40%);
  display: flex;
  justify-content: center;
  align-items: center;
}
