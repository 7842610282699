@import "src/theme/mixins/utils";

.logo {
  img {
    margin: 0 auto;
    @include pxToRem(margin-bottom, 32);
    @include pxToRem(padding, 6);
    @include pxToRem(max-width, 120);
  }
}

.avatar {
  div {
    margin: 0 auto;
    @include pxToRem(margin-top, 32);
    @include pxToRem(margin-bottom, 32);
  }
}
