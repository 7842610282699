@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border-radius: var(--wohnsinn-border-radius);
  transition: box-shadow .3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--ion-color-dark);

  @include pxToRem(gap, 16);
  @include pxToRem(padding-left, 30);
  @include pxToRem(padding-right, 30);
  @include pxToRem(padding-top, 32);
  @include pxToRem(padding-bottom, 32);

  @include for-min-size(md) {
    @include pxToRem(gap, 32);
  }

  &.blue {
    background: var(--ion-color-tertiary);
  }

  &.red {
    background: var(--ion-color-danger-tint);
  }

  &.green {
    background: var(--ion-color-success-tint);
  }

  &.grey {
    background: var(--ion-color-light);
  }

  &.yellow {
    background: var(--ion-color-tertiary);
  }

  &.white {
    background-color: var(--ion-background-color);
    bordeR: 1px solid var(--wohnsinn-border-color);
  }

  .headlineWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include pxToRem(margin-bottom, 6);
  }

  .arrow {
    transition: transform .3s;
  }

  .iconWrapper {
    display: flex;
    align-items: flex-end;
    @include pxToRem(height, 20);

    @include for-min-size(md) {
      @include pxToRem(height, 40);
    }

    color: var(--ion-color-primary-shade);
  }

  .icon {
    @include pxToRem(max-width, 80);
  }

  &:hover {
    @include boxShadow;

    .arrow {
      transform: translateX(8px);
    }
  }
}
