@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  a {
    text-decoration: none;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 32);

  @include for-min-size(md) {
    flex-direction: row;
  }

  .blog {
    position: relative;
    flex: 1 1 0;

    .logo {
      @include pxToRem(margin-bottom, 24);
      @include pxToRem(height, 60);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 0;
      background-color: var(--wohnsinn-border-color);
      transition: width .2s ease-in;
      @include pxToRem(height, 1);
      @include pxToRem(bottom, -16);
    }

    &::before {
      @include pxToRem(top, -16);
    }

    .icon {
      transition: color .2s;
      position: absolute;
      top: 5px;
      right: 5px;
      @include pxToRem(top, 5);
      @include pxToRem(right, 5);
    }

    &:hover {
      &::before,
      &::after {
        transition: width .2s ease-out;
        width: 100%;
      }

      .icon {
        color: var(--ion-color-primary);
      }
    }

  }
}
