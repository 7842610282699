@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ctaButton {
  // Remove shadow dom styling
  display: inline-block;
  box-shadow: none;
  background: none;
  cursor: pointer;
  border: 1px solid var(--ion-color-primary);
  border-radius: var(--wohnsinn-border-radius);
  margin: 0;
  text-transform: none;
  transition: all .3s;
  text-align: center;
  white-space: nowrap;
  text-wrap: none;
  position: relative;
  @include pxToRem(height, 40);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  .badge {
    position: absolute;
    background-color: var(--ion-color-danger);
    border-radius: 9999px;
    @include pxToRem(top, -8);
    @include pxToRem(right, -8);
    @include pxToRem(width, 14);
    @include pxToRem(height, 14);
  }

  &.rounded {
    @include pxToRem(border-radius, 9999);
  }

  &.mobileNotRounded {
    @include for-max-size(md) {
      border-radius: var(--wohnsinn-border-radius);
    }
  }


  &.linkButton {
    text-decoration: none;
  }

  .content {
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }


  svg {
    color: var(--ion-text-color-light);
  }

  &:hover {
    background: var(--ion-color-primary-tint);
    @include boxShadow;
  }

  &.expand {
    width: 100%;
  }


  &.expandMobile {
    @include for-max-size(md) {
      width: 100%;
    }
  }

  &.tiny {
    padding-left: 6px;
    padding-right: 6px;

    @include pxToRem(height, 26);
  }

  &.small {
    padding-left: 8px;
    padding-right: 8px;

    @include pxToRem(height, 32);
  }

  &.extra {
    padding-left: 32px;
    padding-right: 32px;

    @include pxToRem(height, 48);
  }

  &.big {
    padding-left: 32px;
    padding-right: 32px;

    @include pxToRem(height, 66);
  }

  &.hideText {
    padding-left: 0;
    padding-right: 0;

    @include pxToRem(width, 40);

    &.tiny {
      @include pxToRem(width, 26);
    }

    &.small {
      @include pxToRem(width, 32);
    }

    &.big {
      padding-left: 14px;
      padding-right: 14px;

      @include pxToRem(width, 66);
    }
  }

  &.primary {
    background: var(--ion-color-primary);
  }

  &.danger {
    background-color: var(--ion-color-danger);
    border-color: var(--ion-color-danger);

    span,
    p {
      color: var(--ion-text-color-light);
    }

    &:hover {
      background-color: var(--ion-color-danger-shade);
      border-color: var(--ion-color-danger-shade);
      color: var(--ion-color-danger-shade);
    }
  }

  &.secondary {
    border-color: var(--wohnsinn-border-color);
    background-color: var(--ion-background-color);

    svg {
      color: var(--ion-color-primary);
    }

    p,
    span {
      color: var(--ion-text-color);
    }

    &:hover {
      border-color: var(--ion-color-tertiary);
      color: var(--ion-color-primary);
    }
  }

  &.tertiary-light,
  &.tertiary {
    border-color: transparent;
    background-color: transparent;
    color: var(--ion-text-color);

    &:hover {
      color: var(--ion-color-primary);
      text-decoration: underline;
      box-shadow: none;
    }

    svg {
      color: var(--ion-text-color-accent);
    }
  }

  &.TEXT_COLOR_DARK {
    svg {
      color: var(--ion-color-dark);
    }
  }

  &.TEXT_COLOR_LIGHT {
    svg {
      color: var(--ion-text-color-light);
    }
  }

  &.TEXT_COLOR_ACCENT {
    svg {
      color: var(--ion-text-color-accent);
    }
  }

  &.TEXT_COLOR_DANGER {
    svg {
      color: var(--ion-color-danger);
    }
  }

  &.TEXT_COLOR_PRIMARY {
    svg {
      color: var(--ion-color-primary);
    }
  }

  &.danger-inverted {
    color: var(--ion-color-danger);
    border-color: var(--wohnsinn-border-color);
    background-color: var(--ion-background-color);

    &:hover {
      background-color: var(--ion-background-color);
      border-color: var(--ion-color-danger-shade);
      color: var(--ion-color-danger-shade);
    }

    .icon svg {
      color: var(--ion-color-danger);
    }
  }


  &.disabled {
    background: grey;
    border: grey;
    opacity: 0.6;

    &:hover {
      background: grey;
      border: grey;
      box-shadow: none !important;
    }
  }

  .img {
    width: 16px;
    @include pxToRem(width, 16);

    &.big {
      @include pxToRem(width, 32);
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    @include pxToRem(margin-left, -1);
  }

  .img,
  .icon {
    @include pxToRem(margin-right, 6);


    &.end {
      margin-right: 0;
      @include pxToRem(margin-left, 8);
    }

    &.big {
      @include pxToRem(font-size, 34);
    }

    &.hideText {
      margin-right: 0;
    }
  }

}
