@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ctaBar {
  display: flex;
  align-items: center;

  @include pxToRem(gap, 10);
  @include for-max-size(lg) {
    flex-direction: column;
    width: 100%;
  }
}

.emptyList {
  @include pxToRem(margin-top, 100);
}

.fullWidthButton {
  width: 100%;
}

.imageContainer {
  background-size: cover;
  background-position: center;

  @include pxToRem(border-radius, 10);
  @include pxToRem(height, 50);
  @include pxToRem(width, 80);
}

.applicationLink {
  color: var(--ion-color-primary);
}

.selectionActiveBar {
  padding: 1rem;
  display: flex;
  background-color: var(--ion-color-primary);
  flex-flow: row;
  justify-content: space-between;
  animation: growFromBottom 0.3s forwards;
  @include pxToRem(height, 48);
  @include pxToRem(padding, 16);

  transform-origin: bottom;
  align-items: center;
  width: 100%;

  .controls {
    display: flex;
    flex-flow: row;
    gap: 1rem;

    .controlButtons {
      display: flex;
      gap: 1rem;
    }
  }
}

.divider {
  background-color: var(--ion-color-light);
  @include pxToRem(width, 1.5);
}

@keyframes growFromBottom {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

.tableCell {
  &:hover {
    --ion-text-color: var(--ion-color-primary);

    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: var(--ion-color-primary);
  }

  .tableCellText{

    @include for-min-size(lg) {
      @include pxToRem(max-width, 110);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
    }
  }
}

.stickyCell {
    position: sticky;
    right: 0;
    background: white;
}
