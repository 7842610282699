@import "src/theme/mixins/apartment-map-layout";
@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.wrapper {
  @include wrapperLayout;
}

.apartmentInformation {
  @include apartmentLayout(false);
}

.map {
  @include mapLayout;
}
