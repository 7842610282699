@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.modal {
  position: relative;
}

.wrapper {
  overflow-y: scroll;
  @include pxToRem(max-height, 600);
}

.cropWrapper {
  display: flex;
  flex-flow: column;
}

.croppedImage {
  border-radius: 9999px;
}

.buttonWrapper {
  position: absolute;
  align-self: center;
  @include pxToRem(bottom, 16);
  @include pxToRem(margin-top, 16);
  @include pxToRem(left, 16);
  @include pxToRem(right, 16);

  button {
    margin-left: auto;
    margin-right: auto;
  }

}
