@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.appWrapper {
  position: absolute;
  inset: 0;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include pxToRem(padding-top, $wohnsinn-mobile-header-height); // Because of fixed header

  @include for-min-size(lg) {
    @include pxToRem(padding-top, $wohnsinn-desktop-header-height); // Because of fixed header
  }

  .children {
    position: relative;
    flex: 1;
  }


  .appHeader {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--z-index-tooltip);
  }

}
