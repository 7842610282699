@import "src/theme/mixins/utils";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--ion-color-primary);
  position: sticky;
  top: 0;
  z-index: 1;
  @include pxToRem(padding-top, 16);
  @include pxToRem(padding-bottom, 16);
  @include pxToRem(padding-right, 12);
  @include pxToRem(padding-left, 12);
  @include pxToRem(gap, 12);

}
