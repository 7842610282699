@import "src/theme/mixins/utils";

.wrapper {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageWrapper {
  img {
    @include pxToRem(max-width, 200);
  }
}
