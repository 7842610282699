@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.appWrapper {
  display: flex;
  flex-direction: column;

  @include for-min-size(md) {
    min-height: 100vh;
  }

  .appHeader {
    z-index: var(--z-index-fixed);
    position: sticky;
    top: 0;
  }

  .appContent {
    flex: 1;
    @include pxToRem(margin-bottom, 24);
    @include pxToRem(padding-top, 16);

    @include for-min-size(md) {
      @include pxToRem(margin-bottom, 36);
      @include pxToRem(padding-top, 32);
    }
  }

}
