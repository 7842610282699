@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  width: 100%;

  @include for-min-size(md) {
    @include pxToRem(max-width, 500);
  }
}
