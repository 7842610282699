@import "src/theme/mixins/utils";

.progressBar {
  width: 100%;
  background-color: var(--ion-color-light-shade);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(height, 10);

  .progress {
    transition: width .4s;
    border-radius: var(--wohnsinn-border-radius);
    background: var(--ion-color-primary);
    @include pxToRem(height, 10);
  }
}
