@import "mixins/responsive";

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
/* stylelint-disable */
:root {

  // !! AVOID USING WOHNSINN COLOR VARIABLES IN YOU SCSS FILES !!
  // !! TRY ONLY TO USE IONIC COLOR VARIABLES IN YOU SCSS FILES !!
  // !! ALWAYS DOUBLE CHECK THE DARK MODE STYLE OF YOUR COMPONENT !!


  //-----------------
  // WOHNSINN COLORS
  //-----------------

  // Primary Strong
  --color-primary--strong: #18CCDE;
  --color-primary--strong-rgb: 24, 204, 222;
  // Primary Medium
  --color-primary--medium: #D4F8FF;
  --color-primary--medium-rgb: 212, 248, 255;
  // Primary Light
  --color-primary--light: #E1F7FB;
  --color-primary--light-rgb: 225, 247, 251;

  // Grey Strong
  --color-grey--strong: var(--ion-color-dark-tint);
  --color-grey--strong-rgb: 16, 24, 40;
  // Grey Medium
  --color-grey--medium: #344054;
  --color-grey--medium-rgb: 52, 64, 84;
  // Grey Light
  --color-grey--light: #667085;
  --color-grey--light-rgb: 102, 112, 133;
  // Grey Super Light
  --color-grey--super-light: #D0D5DD;
  --color-grey--super-light-rgb: 208, 213, 221;
  // Grey Ultra Light
  --color-grey--ultra-light: #F9FAFB;
  --color-grey--ultra-light-rgb: 249, 250, 251;

  // Red Strong
  --color-red--strong: #FF4646;
  --color-red--strong-rgb: 255, 70, 70;
  // Red Very Strong
  --color-red--very-strong: #F6090D;
  --color-red--very-strong-rgb: 246, 9, 13;
  // Red Light
  --color-red--light: #FCF2F3;
  --color-red--light-rgb: 252, 242, 243;

  // Green Strong
  --color-green--strong: #A1DE18;
  --color-green--strong-rgb: 161, 222, 24;
  // Green Very Strong
  --color-green--very-strong: #8FCE00;
  --color-green--very-strong-rgb: 143, 206, 0;
  // Green Light
  --color-green--light: #F5FBEE;
  --color-green--light-rgb: 245, 255, 224;

  // Yellow Strong
  --color-yellow--strong: #FFEE6E;
  --color-yellow--strong-rgb: 255, 238, 110;
  // Yellow Very Strong
  --color-yellow--very-strong: #E0D161;
  --color-yellow--very-strong-rgb: 224, 209, 97;
  // Yellow Light
  --color-yellow--light: #FFF07D;
  --color-yellow--light-rgb: 255, 240, 125;


  //---------------------------------------------------
  // IONIC COLOR PALETTE WITH WOHNSINN COLOR VARIABLES
  //---------------------------------------------------
  --ion-color-primary: var(--color-primary--strong);
  --ion-color-primary-rgb: var(--color-primary--strong-rgb);
  --ion-color-primary-contrast: var(--color-grey--strong);
  --ion-color-primary-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-primary-shade: #15b4c3;
  --ion-color-primary-tint: #2fd1e1;
  --ion-color-primary-transparency: rgb(24, 204, 222, 0.4);

  --ion-color-secondary: var(--color-primary--medium);
  --ion-color-secondary-rgb: var(--color-primary--medium-rgb);
  --ion-color-secondary-contrast: var(--color-grey--strong);
  --ion-color-secondary-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-secondary-shade: #bbdae0;
  --ion-color-secondary-tint: #d8f9ff;

  --ion-color-tertiary: var(--color-primary--light);
  --ion-color-tertiary-rgb: var(--color-primary--light-rgb);
  --ion-color-tertiary-contrast: var(--color-grey--strong);
  --ion-color-tertiary-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-tertiary-shade: #c6d9dd;
  --ion-color-tertiary-tint: #e4f8fb;

  --ion-color-success: var(--color-green--strong);
  --ion-color-success-rgb: var(--color-green--strong-rgb);
  --ion-color-success-contrast: var(--color-grey--strong);
  --ion-color-success-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-success-shade: var(--color-green--very-strong);
  --ion-color-success-tint: var(--color-green--light);

  --ion-color-warning: var(--color-yellow--strong);
  --ion-color-warning-rgb: var(--color-yellow--strong-rgb);
  --ion-color-warning-contrast: var(--color-grey--strong);
  --ion-color-warning-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-warning-shade: var(--color-yellow--very-strong);
  --ion-color-warning-tint: var(--color-yellow--light);

  --ion-color-danger: var(--color-red--strong);
  --ion-color-danger-rgb: var(--color-red--strong-rgb);
  --ion-color-danger-contrast: var(--color-grey--strong);
  --ion-color-danger-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-danger-shade: var(--color-red--very-strong);
  --ion-color-danger-tint: var(--color-red--light);

  --ion-color-dark: #043647;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #01171f;
  --ion-color-dark-tint: #012f40;

  --ion-color-medium: var(--color-grey--medium);
  --ion-color-medium-rgb: var(--color-grey--medium-rgb);
  --ion-color-medium-contrast: var(--color-grey--ultra-light);
  --ion-color-medium-contrast-rgb: var(--color-grey--ultra-light-rgb);
  --ion-color-medium-shade: var(--color-grey--strong);
  --ion-color-medium-tint: var(--color-grey--light);

  --ion-color-light: var(--color-grey--ultra-light);
  --ion-color-light-rgb: var(--color-grey--super-light-rgb);
  --ion-color-light-contrast: var(--color-grey--strong);
  --ion-color-light-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-light-shade: #d7d8da;
  --ion-color-disabled: rgba(217, 217, 217, 0.3);
  --ion-color-light-tint: #ffffff;

  // COLOR SETTINGS (ADDED BY WOHNSINN)
  // IF ADDING NEW SETTINGS MAKE SURE TO ADD THEM TO ALL DARK MODE VARIANTS BELOW
  --wohnsinn-border-color: var(--ion-color-light-shade);
  --ion-background-color: var(--ion-color-light-tint);
  --wohnsinn-body-background-color: var(--ion-color-light);

  // TEXT COLORS
  --ion-text-color: var(--color-grey--strong);
  --ion-text-color-light: var(--ion-color-light);
  --ion-text-color-accent: #475467;

  // Grid
  --ion-grid-width: 1140px;

  // Global Font
  --ion-font-family: 'F37 Ginger';


  // Spacings & Utils
  --wohnsinn-border-radius: 10px;
  --wohnsinn-mobile-header-height: 53px;
  --wohnsinn-desktop-header-height: 70px;

  // FONT-WEIGHTS
  --font-weight--light: 100;
  --font-weight--regular: 400;
  --font-weight--bold: 700;

  // Z-INDEX VARIABLE
  --z-index-base:                 1;
  --z-index-indicatior:            900;
  --z-index-dropdown:             1000;
  --z-index-sticky:               1020;
  --z-index-fixed:                1030;
  --z-index-modal-backdrop:       1040;
  --z-index-modal:                1050;
  --z-index-popover:              1060;
  --z-index-tooltip:              1070;

  --z-index-lightshow-backdrop:   30001;
  --z-index-lightshow:            30002;



  --light-show-images: 9999;
  --light-show-backdrop: 9998;
  --media-gallery-slide-indicator: 9997;
  --navigation-position: 9996;
}

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-color-primary: var(--color-primary--strong);
  --ion-color-primary-rgb: var(--color-primary--strong-rgb);
  --ion-color-primary-contrast: var(--color-grey--light);
  --ion-color-primary-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-primary-shade: #15b4c3;
  --ion-color-primary-tint: #2fd1e1;

  --ion-color-secondary: var(--color-primary--medium);
  --ion-color-secondary-rgb: var(--color-primary--medium-rgb);
  --ion-color-secondary-contrast: var(--color-grey--ultra-light);
  --ion-color-secondary-contrast-rgb: var(--color-grey--ultra-light-rgb);
  --ion-color-secondary-shade: #bbdae0;
  --ion-color-secondary-tint: #d8f9ff;

  --ion-color-tertiary: var(--ion-color-primary-tint);
  --ion-color-tertiary-rgb: var(--color-primary--light-rgb);
  --ion-color-tertiary-contrast: var(--color-grey--strong);
  --ion-color-tertiary-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-tertiary-shade: #c6d9dd;
  --ion-color-tertiary-tint: #e4f8fb;

  --ion-color-success: var(--color-green--strong);
  --ion-color-success-rgb: var(--color-green--strong-rgb);
  --ion-color-success-contrast: #CAEA78;
  --ion-color-success-contrast-rgb: 202, 234, 120;
  --ion-color-success-shade: var(--color-green--very-strong);
  --ion-color-success-tint: var(--ion-color-success-contrast-rgb);

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: var(--color-grey--strong);
  --ion-color-warning-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: var(--color-grey--strong);
  --ion-color-danger-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: var(--color-grey--strong);
  --ion-color-dark-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: var(--color-grey--strong);
  --ion-color-medium-contrast-rgb: var(--color-grey--strong-rgb);
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: var(--color-grey--strong);
  --ion-color-light-rgb: var(--color-grey--strong-rgb);
  --ion-color-light-contrast: var(--color-grey--ultra-light);
  --ion-color-light-contrast-rgb: var(--color-grey--ultra-light-rgb);
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;


  // COLOR SETTINGS (ADDED BY WOHNSINN)
  // IF ADDING NEW SETTINGS MAKE SURE TO ADD THEM TO ALL DARK MODE VARIANTS BELOW
  --wohnsinn-border-color: var(--ion-color-light-tint);
  --ion-text-color: var(--color-grey--ultra-light);
  --ion-text-color-light: var(--ion-color-medium-contrast);
  --ion-text-color-accent: #475467;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  --ion-background-color: var(--color-grey--strong);
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: var(--color-grey--ultra-light);
  --ion-text-color-light: #E5E7EB;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: var(--color-grey--strong);

  --ion-card-background: #1c1c1d;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: var(--color-grey--ultra-light);
  --ion-text-color-light: #E5E7EB;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background: #1f1f1f;
  --ion-card-background: #1e1e1e;
}
