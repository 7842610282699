@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";


.wrapper {
  @include for-min-size(md) {
    float: left;
  }
}

.emptyTableRow {
  background-color: var(--ion-color-tertiary);
  @include pxToRem(margin-top, 12);
  @include pxToRem(padding, 16);
}

.segmentLabel {
  display: none;

  @include for-min-size(md) {
    display: inline;
  }
}

.segmentIcon {
  display: inline-block;
  @include pxToRem(margin-left, 6);
  @include pxToRem(width, 20);
}

.filterBar {
  display: flex;
  flex-direction: column;
  @include pxToRem(gap, 8);
  @include pxToRem(margin-bottom, 24);

  @include for-min-size(md) {
    flex-direction: row;
    @include pxToRem(gap, 16);
  }
}

.chatWrapper {
  width: 100vw;
  height: 100%;
}
