@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.inputWrapper {
  position: relative;
  width: 100%;

  svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: grey;
  }
}

.input {
  background-color: var(--ion-background-color);
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  @include pxToRem(padding-left, 35);
  @include pxToRem(padding-right, 10);
  @include pxToRem(height, 40);

  width: 100%;

}
