@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  position: relative;
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);
  overflow: hidden;
  @include pxToRem(min-height, 230);

  .imageWrapper {
    position: absolute;
    inset: 0;
    z-index: 999;
    cursor: pointer;
  }

  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}


