@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  position: sticky;
  top: 0;
  z-index: var(--z-index-sticky);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--wohnsinn-mobile-header-height);
  background-color: var(--ion-background-color);
  border-bottom: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-min-size(md) {
    height: var(--wohnsinn-desktop-header-height);
  }

  .left {
    height: 100%;
    display: flex;
    align-items: center;
    @include pxToRem(gap, 85);

    .logo {
      background-repeat: no-repeat;

      @include pxToRem(margin-top, 13);
      @include pxToRem(margin-bottom, 13);
      @include pxToRem(height, 20);
      @include pxToRem(width, 120);
    }
  }
}


.menu {
  display: flex;
  height: 100%;
  @include pxToRem(gap, 45);

  @include for-max-size(md) {
    display: none;
  }

  .parent {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include pxToRem(margin-top, 3);

    a {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .childTitle {
      @include pxToRem(padding, 12);
    }

    .child {
      display: none;
      top: 100%;
      left: 0;
      position: absolute;
      border-radius: var(--wohnsinn-border-radius);
      background-color: var(--ion-background-color);
      white-space: nowrap;
      @include pxToRem(padding, 12);
      @include boxShadow;
      @include pxToRem(width, 280);

      .subMenu {
        li {
          a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: var(--wohnsinn-border-radius);
            @include pxToRem(gap, 12);
            @include pxToRem(padding, 12);
            @include pxToRem(padding-right, 12);
            @include pxToRem(margin-bottom, 8);

            &:hover {
              background-color: var(--ion-color-tertiary);
            }
          }
        }
      }
    }

    &:hover {
      cursor: pointer;

      .child {
        display: block;
      }
    }
  }

}

.mobileWrapper {
  display: flex;
  align-items: center;
  @include pxToRem(gap, 24);

  .buttonWrapper {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 8);
  }
}


.hideMobile {
  display: none;

  @include for-min-size(lg) {
    display: block;
  }
}
