@import "src/theme/mixins/responsive";
@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";

.left, .middle, .right {
  transition: width .4s, transform .4s;
  position: absolute;
  top: 0;
  bottom: 0;

  @include hideScrollBars;
}

.left {
  width: 100%;
  transform: translateX(-100%);
  overflow-y: scroll;

  &.show {
    transform: translateX(0);
  }

  @include for-min-size(md) {
    border-right: 1px solid var(--wohnsinn-border-color);
    transform: translateX(0);
    @include pxToRem(width, 400);
  }

  @include for-min-size(lg) {
    @include pxToRem(width, 450);
  }
}

.middle {
  width: 100%;
  right: 0;

  @include for-max-size(md) {
    &.showLeft {
      width: 100%;
      transform: translateX(100%);
    }
  }

  @include for-min-size(md) {
    width: calc(100% - 400px);

    &.showRight {
      transform: translateX(0);
    }
  }

  @include for-min-size(lg) {
    width: calc(100% - 850px);
    right: 400px;
  }

  .children {
    height: 100%;
  }
}

.right {
  background-color: var(--ion-color-light-tint);
  width: 100%;
  transform: translateX(100%);
  right: 0;
  z-index: 1;
  overflow-y: scroll;

  &.show {
    transform: translateX(0);
  }

  @include for-min-size(md) {
    border-left: 1px solid var(--wohnsinn-border-color);
    width: calc(100% - 400px);
  }

  @include for-min-size(lg) {
    transform: translateX(0);
    @include pxToRem(width, 400);
  }
}

