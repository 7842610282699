@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  @include pxToRem(margin-bottom, 80);

  @include for-min-size(md) {
    @include pxToRem(margin-bottom, 55);
  }

  :global(.ql-editor) {
    max-height: 60px;
    @include pxToRem(max-height, 115);
  }

  &.noMargin {
    margin-bottom: 0;
  }

  &.LARGE {
    @include pxToRem(height, 300);

    :global(.ql-editor) {
      max-height: 60px;
      @include pxToRem(max-height, 300);
    }
  }

  &.MEDIUM {
    @include pxToRem(height, 200);

    :global(.ql-editor) {
      max-height: 60px;
      @include pxToRem(max-height, 200);
    }
  }
}
