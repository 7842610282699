.app-logo {
  background-image: url('../../public/assets/icon/wohnsinn-logo.svg');
}

.woge-logo {
  background-image: url('../../public/assets/icon/woge_logo.png');
}

.dark {
  .app-logo {
    background-image: url('../../public/assets/icon/wohnsinn-logo-white.svg');
  }

  .woge-logo {
    background-image: url('../../public/assets/icon/woge_logo.png');
  }
}
