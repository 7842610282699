@import "src/theme/mixins/utils";

.imgWrapper {
  position: relative;

  .small {
    @include pxToRem(width, 42);
    @include pxToRem(height, 35);
  }

  .medium {
    @include pxToRem(width, 82);
    @include pxToRem(height, 70);
  }

  .large {
    @include pxToRem(width, 144);
    @include pxToRem(height, 105);
  }

  .img {
    transform: translateX(-50%);

    &.animate {
      animation: bounce .6s infinite alternate;
    }
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-30px);
  }
}
