@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.spacer {
  @include pxToRem(height, 24);

  &.xs {
    @include pxToRem(height, 8);
  }

  &.sm {
    @include pxToRem(height, 12);
  }

  &.md {
    @include pxToRem(height, 16);
  }

  &.lg {
    @include pxToRem(height, 32);
  }

  &.xl {
    @include pxToRem(height, 48);
  }

  @include for-max-size(md) {
    &.mobile-- {
      &xs {
        @include pxToRem(height, 8);
      }

      &sm {
        @include pxToRem(height, 12);
      }

      &md {
        @include pxToRem(height, 16);
      }

      &lg {
        @include pxToRem(height, 32);
      }

      &xl {
        @include pxToRem(height, 48);
      }

      &none {
        height:0;
      }
    }
  }
}

