@import "src/theme/mixins/utils";

.wrapper {
  position: absolute;
  display: flex;
  z-index: var(--z-index-sticky);
  @include pxToRem(left, 12);
  @include pxToRem(top, 12);
  @include pxToRem(gap, 5);
}
