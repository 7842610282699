@import "src/theme/mixins/fonts";
@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  border-top: 1px solid var(--ion-color-light-shade);
  background: var(--ion-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  @include pxToRem(gap, 32);
  @include pxToRem(padding-top, 10);
  @include pxToRem(padding-bottom, 10);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  @include for-min-size(md) {
    box-shadow: none;
    border-radius: 0;
    border-right: 1px solid var(--wohnsinn-border-color);
    @include pxToRem(margin-right, 0);
    @include pxToRem(margin-left, 0);
  }

  .buttonAnimation {
    @include buttonAnimation;
  }

  .singleButton {
    width: 90%;
  }
}
