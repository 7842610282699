@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(padding-top, 16);

  .address {
    display: flex;
    justify-content: space-between;

    h1 {
      flex: 1;
    }

    span {
      flex: 1;
    }
  }
}

