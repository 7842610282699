@import "src/theme/mixins/utils";

.loadingApartmentCard {
  border: 1px solid var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  width: 370px;
  height: 360px;
  @include boxShadow;

  .text,
  .image {
    width: 100%;
    @include loadingSkeleton;
  }

  .image {
    height: 170px;
    margin-bottom: 16px;
  }

  .textWrapper {
    padding: 1rem;
    @include pxToRem(margin-bottom, 16);

    .text {
      @include pxToRem(height, 16);
      @include pxToRem(margin-bottom, 8);
    }
  }
}

