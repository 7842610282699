@import "src/theme/mixins/fonts";
@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.wrapper {
  position: relative;

  .inputWrapper {
    width: 100%;
    position: relative;

    .input {
      width: 100%;
      border-radius: 9999px;
      background-color: var(--ion-background-color);
      border: 1px solid var(--wohnsinn-border-color);
      @include pxToRem(padding-left, 18);
      @include pxToRem(padding-right, 100);
      @include pxToRem(padding-top, 16);
      @include pxToRem(padding-bottom, 16);

      &:focus {
        border-color: var(--ion-color-primary);
        outline: none;
      }
    }

    .cta {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      @include pxToRem(right, 10);
    }
  }

  .optionWrapper {
    background-color: var(--ion-background-color);
    z-index: 10;
    position: absolute;
    width: 100%;
    border: 1px solid var(--wohnsinn-border-color);
    border-radius: var(--wohnsinn-border-radius);
    overflow: hidden;
    left: 0;
    @include pxToRem(top, 64);


    .option {
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-top, 8);
      @include pxToRem(padding-bottom, 8);

      &:hover, &:active, &:focus {
        outline: none;
        cursor: pointer;
        background-color: var(--ion-color-primary);
      }
    }
  }

  .mapWrapper {
    border-radius: var(--wohnsinn-border-radius);
    background-color: var(--ion-background-color);
    border: 1px solid var(--wohnsinn-border-color);
    position: absolute;
    width: 100%;
    z-index: 100;
    left: 0;
    @include pxToRem(top, 64);
    @include pxToRem(padding, 12);
    @include pxToRem(padding-top, 8);
  }
}

