@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.linkElement {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px !important;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: var(--wohnsinn-border-radius);
  background: var(--ion-background-color);
  border: 1px solid var(--wohnsinn-border-color);
  cursor: pointer;

  &:hover {
    @include for-min-size(md) {
      background: var(--ion-color-light-shade);
    }
  }

  .label {
    display: flex;
    align-items: center;
    @include pxToRem(gap, 8);

    svg {
      @include pxToRem(margin-right, 8);
    }
  }

}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &.red {
    background-color: var(--ion-color-danger);
  }

  &.blue {
    background-color: var(--ion-color-primary);
  }

  @include pxToRem(width, 25);
  @include pxToRem(height, 25);
  @include pxToRem(border-radius, 50);
}
