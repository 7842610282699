@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.ctaCard {
  width: 100%;
  border-radius: var(--wohnsinn-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include pxToRem(gap, 20);

  &.alternative {
    background-color: var(--ion-color-tertiary);
    border-radius: var(--wohnsinn-border-radius);
    @include pxToRem(padding, 52);
    @include pxToRem(gap, 16);
  }

  &.inverted {
    background-color: var(--ion-color-dark-shade);
    @include pxToRem(padding, 52);
    @include pxToRem(gap, 16);
  }

}

.image {
  @include pxToRem(max-width, 200);
}


.ctaWrapper {
  width: 100%;
  display: flex;
  justify-content: center;

  @include pxToRem(gap, 10);

  @include for-max-size(md){
    flex-direction: column;
  }
}
