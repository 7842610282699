@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.swiperWrapper {
  cursor: pointer;
  position: relative;

  &.reelsView {
    height: 100%;
  }

  .link {
    display: none;
    @include for-min-size(md) {
      display: block;
    }

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }


  .buttons {
    display: none;

    @include for-min-size(md) {
      display: block;
      position: absolute;
      top: 50%;
      z-index: 3;
      width: 30px;
      height: 30px;
      background-color: var(--ion-background-color);
      border-radius: 9999px;
      transform: translateY(-50%);
      transition: opacity .4s;
      @include boxShadow;

      @include for-min-size(md) {
        opacity: 0;
        visibility: hidden;
      }

      &.prev {
        left: 10px;
      }

      &.next {
        right: 10px;
      }
    }

  }

  &:hover {
    .buttons {
      visibility: visible;
      opacity: 1;
    }
  }


  .slides {
    display: flex;
    aspect-ratio: 3 / 2;
    overflow: hidden;

    &.reelsView {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .slide {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      justify-content: center;
      background-color: var(--ion-color-disabled);

      @include for-min-size(md) {
        border: 1px solid transparent;
      }

      img {
        width: 100%; /* Make sure the image takes up the full width of the div */
        height: 100%; /* Make sure the image takes up the full height of the div */
        object-fit: cover; /* This will cover the area, similar to background-size: cover */
      }
    }
  }

  .indexIndicator {
    letter-spacing: 1px;
    background: rgb(0 0 0 / 40%);
    color: var(--ion-color-light);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-indicatior);
    font-weight: bold;
    border-radius: var(--wohnsinn-border-radius);
    @include pxToRem(font-size, 12);
    @include pxToRem(padding-left, 8);
    @include pxToRem(padding-right, 8);
    @include pxToRem(padding-top, 4);
    @include pxToRem(padding-bottom, 4);
    @include pxToRem(right, 12);
    @include pxToRem(top, 12);
  }
}

