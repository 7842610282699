@import "src/theme/mixins/utils";

.mockup {
  justify-content: center;
  align-items: center;
  display: flex;

  .showImage {
    animation: fadeIn .5s;
  }

  .hideImage {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
