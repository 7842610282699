@import "../../../../theme/mixins/responsive";
@import "../../../../theme/mixins/utils";


h1.headline,
h2.headline,
h3.headline,
h4.headline {
  margin-top: 0;
  margin-bottom: 0;
}

.headline {
  color: var(--ion-text-color);
  font-weight: 600;

  &.breakWord {
    word-break: break-word;
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.noWrap {
    white-space: nowrap;
  }

  // COLORS
  &.HEADLINE_COLOR_DARK {
    color: var(--ion-text-color);
  }

  &.HEADLINE_COLOR_LIGHT {
    color: var(--ion-text-color-light);
  }

  &.HEADLINE_COLOR_PRIMARY {
    color: var(--ion-color-primary);
  }

  &.underline {
    text-decoration: underline;
  }

  @include for-max-size(md) {
    &.mobile-left {
      text-align: left;
    }

    &.mobile-center {
      text-align: center;
    }

    &.mobile-right {
      text-align: right;
    }
  }
}


@mixin heroSize() {
  @include pxToRem(font-size, 40);
  @include pxToRem(line-height, 44);
}

.hero {
  @include heroSize;

  @include for-min-size(lg) {
    @include pxToRem(font-size, 72);
    @include pxToRem(line-height, 90);
  }
}


@mixin displaySize() {
  @include pxToRem(font-size, 30);
  @include pxToRem(line-height, 38);
}

.display {
  @include displaySize;

  @include for-min-size(lg) {
    @include pxToRem(font-size, 60);
    @include pxToRem(line-height, 72);
  }
}


@mixin h1Size() {
  @include pxToRem(font-size, 30);
  @include pxToRem(line-height, 38);
}

.h1 {
  @include h1Size;

  @include for-min-size(lg) {
    @include pxToRem(font-size, 36);
    @include pxToRem(line-height, 44);
  }
}



@mixin h2Size() {
  @include pxToRem(font-size, 24);
  @include pxToRem(line-height, 32);
}

.h2 {
  @include h2Size;

  @include for-min-size(lg) {
    @include pxToRem(font-size, 30);
    @include pxToRem(line-height, 38);
  }
}


@mixin h3Size() {
  @include pxToRem(font-size, 18);
  @include pxToRem(line-height, 28);
}

.h3 {
  @include h3Size;

  @include for-min-size(lg) {
    @include pxToRem(font-size, 20);
    @include pxToRem(line-height, 30);
  }
}

.mobile {
  @include for-max-size(md) {
    &-display {
      @include displaySize;
    }

    &-h1 {
      @include h1Size;
    }

    &-h2 {
      @include h2Size;
    }

    &-h3 {
      @include h3Size;
    }
  }
}
