@font-face {
  font-family: 'F37 Ginger';
  font-weight: 700;
  src: url("../../public/fonts/F37Ginger-Bold.otf") format('otf'),
  url("../../public/fonts/F37Ginger-Bold.woff2") format('woff2'),
  url("../../public/fonts/F37Ginger-Bold.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'F37 Ginger';
  font-weight: 400;
  src: url("../../public/fonts/F37Ginger-Regular.woff2") format('woff2'),
  url("../../public/fonts/F37Ginger-Regular.woff") format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'F37 Ginger';
  font-weight: 100;
  src: url("../../public/fonts/F37Ginger-Light.ttf") format('ttf'),
  url("../../public/fonts/F37Ginger-Light.woff2") format('woff2'),
  url("../../public/fonts/F37Ginger-Light.woff") format('woff');
  font-display: swap;
}

* {
  font-family: 'F37 Ginger', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

a {
  &:hover {
    color: var(--ion-color-primary);
  }

}
