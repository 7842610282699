@import "../../../theme/mixins/responsive";
@import "../../../theme/mixins/utils";

.tabs {
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(max-width, 400);

  @include for-min-size(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.card {
  height: 100%;
}
