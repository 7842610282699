@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-5px, -5px);
  color: var(--ion-color-light);

  &:hover {
    color: var(--ion-color-primary);
  }

  svg {
    @include for-min-size(md) {
      @include pxToRem(width, 25);
      @include pxToRem(height, 25);
    }

    @include for-min-size(lg) {
      @include pxToRem(width, 33);
      @include pxToRem(height, 33);
    }
  }

  &.dark {
    svg {
      color: var(--ion-color-dark);
      @include pxToRem(font-size, 24);
    }
  }
}
