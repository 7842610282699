@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  height: 100%;
  background-color: var(--ion-background-color);
  color: var(--wohnsinn-border-color);
  border-radius: var(--wohnsinn-border-radius);
  @include boxShadow;

  .content {
    @include pxToRem(padding, 12);

    @include for-min-size(md) {
      @include pxToRem(padding, 16);
    }
  }

  .info {
    position: relative;

    .badge {
      position: absolute;
      right: 0;
    }

    .titleExtra {
      @include pxToRem(margin-left, 12);
    }
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    @include pxToRem(gap, 12);
    @include pxToRem(margin-top, 24);
  }

  .discount {
    text-decoration: line-through;
  }
}

.checkListWrapper {
  border-top: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(margin-top, 24);
  @include pxToRem(padding, 24);

  @include for-min-size(md) {
    @include pxToRem(padding, 32);
  }
}

