@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.appWrapper {
  overflow: hidden;
  @include pxToRem(padding-top, $wohnsinn-mobile-header-height); // Because of fixed header

  @include for-min-size(lg) {
    @include pxToRem(padding-top, $wohnsinn-desktop-header-height); // Because of fixed header
  }

  .appHeader {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: var(--z-index-tooltip);
  }
}
