@import "src/theme/mixins/utils";

.link {
  .linkText {
    color: var(--ion-color-primary);
  }

  &:hover {
    .linkText {
      text-decoration: underline;
    }
  }
}

.wrapper {
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-color-tertiary);
  transition: box-shadow .2s;
  @include pxToRem(padding, 24);

  &:hover {
    @include boxShadow;
  }

  .content {
    display: flex;
    flex-direction: column;
    @include pxToRem(gap, 20);
    @include pxToRem(margin-top, 64);
  }
}
