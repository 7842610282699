@import "src/theme/mixins/utils";

.header {
  width: 100%;
  display: flex; justify-content: space-between;
  align-items: center;
  @include pxToRem(padding-top, 8);
  @include pxToRem(padding-bottom, 8);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);

  .profile {
    display: flex;
    gap: 10px;
    align-items: center;
    @include pxToRem(gap, 10);
  }
}
