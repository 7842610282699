@import "src/theme/mixins/utils";

.titleWrapper {
  padding-bottom: 1rem;

  .image {
    width: 3rem;
    margin: 0 auto;
    @include pxToRem(margin-bottom, 16);
  }
}
