@import "src/theme/mixins/utils";

.wrapper {
  @include pxToRem(margin-top, -17);

  @media print {
    @include pxToRem(margin-top, -13);
  }
}

.item {
  padding: 10px 8px 10px 0;
  background: var(--ion-background-color);
  border-bottom: 1px solid var(--wohnsinn-border-color);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .label {
    text-overflow: clip;
    white-space: normal;

    @media print {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
