@import "src/theme/mixins/responsive";
@import "src/theme/mixins/utils";

.formWrapper {
  position: relative;
  height: 100%;

  @include for-max-size(lg) {
    @include pxToRem(padding-bottom, 101);
  }

  .mobileButtons {
    border-top: 1px solid var(--wohnsinn-border-color);
    z-index: var(--z-index-fixed);
    background-color: var(--ion-background-color);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include pxToRem(padding-top, 16);
    @include pxToRem(padding-bottom, 16);

    @include for-min-size(lg) {
      display: none;
    }
  }

  .desktopButtons {
    display: none;

    @include for-min-size(lg) {
      display: flex;
      justify-content: flex-end;
      @include pxToRem(gap, 12);
      @include pxToRem(margin-top, 24);
    }
  }
}



