@import "src/theme/mixins/utils";

.wrapper {
  height: 100%;
  overflow: hidden;
  border-radius: var(--wohnsinn-border-radius);
  background-color: var(--ion-color-tertiary);
  transition: box-shadow .3s;
  display: flex;
  flex-direction: column;
  @include pxToRem(padding, 20);
  @include boxShadow;

  &:hover {
    box-shadow: none;
  }

  .media {
    background-position: top;
    @include pxToRem(height, 200);

    border-radius: var(--wohnsinn-border-radius);
  }

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include pxToRem(gap, 24);
    @include pxToRem(padding-top, 20);
  }
}
