@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.slider {
  padding-top: 1rem;
  margin: auto;
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;

  .slideTrack {
    display: flex;

    .slide {
      display: flex;
      align-items: center;
      @include pxToRem(padding-left, 40);
      @include pxToRem(padding-right, 40);
      @include pxToRem(perspective, 100);

      img {
        height: auto;
      }

    }
  }
}

