@import "src/theme/mixins/utils";

.wrapper {
  background: var(--ion-background-color);
  border-radius: var(--wohnsinn-border-radius);
  border: 1px solid var(--wohnsinn-border-color);
  @include pxToRem(padding, 24);
  @include pxToRem(margin-top, 38);

  &.noMarginTop {
    margin-top: 0;
  }

  .iconWrapper {
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--ion-color-tertiary);
    @include pxToRem(width, 70);
    @include pxToRem(height, 70);
    @include pxToRem(margin-bottom, 24);

    svg {
      color: var(--ion-color-primary);
    }
  }
}
