@import "src/theme/mixins/utils";
@import "src/theme/mixins/responsive";

.wrapper {
  width: 100vw;
  height: 100%;
  @include pxToRem(padding-top, 16);
  @include pxToRem(padding-left, 16);
  @include pxToRem(padding-right, 16);
  @include pxToRem(padding-bottom, 24);

  @include for-min-size(md) {
    @include pxToRem(padding-top, 24);
    @include pxToRem(padding-left, 32);
    @include pxToRem(padding-right, 32);
    @include pxToRem(padding-bottom, 24);
    @include pxToRem(width, 500);
  }

  &.fullScreen {
    height: calc(100% - 81px);
    padding: 0;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include pxToRem(margin-bottom, 48);

    &.noTitleMargin {
      @include pxToRem(margin-bottom, 0);
    }

    &.fullScreen {
      margin-bottom: 0;
      @include pxToRem(padding-top, 16);
      @include pxToRem(padding-left, 16);
      @include pxToRem(padding-right, 16);
      @include pxToRem(padding-bottom, 24);

      @include for-min-size(md) {
        @include pxToRem(padding-top, 24);
        @include pxToRem(padding-left, 32);
        @include pxToRem(padding-right, 32);
        @include pxToRem(padding-bottom, 24);
      }
    }


    .logo {
      @include pxToRem(height, 20);
      @include pxToRem(margin-bottom, 4);
    }
  }
}
